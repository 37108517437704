<template>
  <div>
    <div class="top flex-ac flex-jb">
      <select v-model="monthDate" class="select">
        <option
          v-for="month in month_list"
          :key="month + 'option'"
          :value="month"
        >
          {{ month.split('-')[0] }} 年 {{ month.split('-')[1] }} 月
        </option>
      </select>
    </div>
    <div v-if="table_data.length > 0" class="table">
      <div
        v-for="(item, index) in table_data"
        :key="item.type_name + index"
        class="table-row flex-ac flex-jb"
      >
        <div class="row-record">提现至： {{ item.type_name }}</div>
        <div class="row-record">
          ({{ item.status_name }}) {{ item.record_date }}
        </div>
        <div class="row-record">+ {{ item.cash }}</div>
      </div>
    </div>
    <div v-else class="empty">暫無數據</div>
    <div class="bottom">
      <div class="hint">
        温馨提示：<br />
        提现到賬有时可能存在延遲，感謝您的理解，如有疑
        问請聯繫客服，我们将幾時为您核實处理。
      </div>
      <div v-if="show_pages" class="pages flex-ac flex-jb">
        <div class="page-btns flex-c">
          <div class="page-btn" @click="firstPage">首页</div>
          <div class="page-btn" @click="prevPage">上一页</div>
          <div class="page-btn" @click="nextPage">下一页</div>
          <div class="page-btn" @click="lastPage">末页</div>
        </div>
        <div class="page-index">第 {{ pages }} 页 共 {{ total_page }} 页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import dayjs from 'dayjs'
import _ from 'lodash'

export default {
  name: 'withdraw_log',
  props: [],
  data() {
    return {
      monthDate: dayjs().format('YYYY-MM'),
      pages: 1,
      rows: 10,
      month_list: [],
      total_page: 10,
      table_data: [],
      show_pages: false
    }
  },
  methods: {
    get_log() {
      const mapStr = JSON.stringify({
        pages: this.pages,
        rows: 5
      })
      post('record_withdrawalRecord.do', this.Qs.stringify({ mapStr })).then(
        res => {
          if (res.code === 'fail') {
            this.$message.error(res.info, 2)
          } else {
            const data = JSON.parse(res.data)
            const { rechargeList, total_num } = data
            this.table_data = rechargeList
            const num = total_num % this.rows //餘數
            if (num > 0) {
              this.total_page = (total_num - num) / this.rows + 1 //頁數
            } else {
              this.total_page = (total_num - num) / this.rows //頁數
            }
            this.show_pages =
              this.total_page !== 0 && this.table_data.length !== 0
          }
        }
      )
    },
    firstPage() {
      this.pages = 1
    },
    prevPage() {
      this.pages = this.pages === 1 ? this.total_page : this.pages - 1
    },
    nextPage() {
      this.pages = this.pages >= this.total_page ? 1 : this.pages + 1
    },
    lastPage() {
      this.pages = this.total_page
    }
  },
  computed: {},
  watch: {
    monthDate() {
      this.get_log()
    },
    pages() {
      this.get_log()
    }
  },
  mounted() {
    this.month_list = _.range(12).map(index => {
      return dayjs().add(-index, 'month').format('YYYY-MM')
    })

    this.get_log()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.select {
  background: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
}

.table {
  width: 100%;
}

.table-row {
  width: 100%;
  background-color: #333337;
  color: #666;
  font-size: 0.8rem;
  margin-bottom: 1px;
  padding: 2rem;

  &:hover {
    background-color: #1f1f23;
    color: #8d8d8d;
  }
}

.empty {
  width: 100%;
  color: #666;
  padding: 2rem;
  text-align: center;
}

// .row-record {
// }

.bottom {
  width: 90%;
  height: 6rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  margin: 0 auto 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .table-row {
    background-color: #313131;
    color: #999;
    padding: 0.5rem;

    &:hover {
      background-color: #1d1d1d;
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
  }
}
</style>
